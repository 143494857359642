// CartPage.tsx

import React from 'react';
import { useCart } from '../common/CartContext';
import CheckoutButton from '../../components/CheckoutButton';

const CartPage = () => {
  const { cart, removeFromCart, updateQuantity } = useCart();

  const handleQuantityChange = (id: string, increment: boolean) => {
    const item = cart.items.find(item => item.id === id);
    if (item) {
      updateQuantity(id, item.quantity + (increment ? 1 : -1));
    }
  };

  // Transform cart items to the format required by Stripe
  const itemsForCheckout = cart.items.map(item => ({
    id: item.id,
    slug: item.slug,
    price: item.price,
    quantity: item.quantity,
  }));

  return (
    <div className="container mx-auto p-8">
      <h1 className="text-4xl font-semibold mb-8">Your Cart</h1>
      {cart.items.length === 0 ? (
        <p>Your cart is empty.</p>
      ) : (
        <div>
          <div className="flex flex-col md:flex-row md:justify-between md:items-center">
            <h1 className="text-2xl font-bold my-4">Shopping Cart</h1>
            <CheckoutButton items={itemsForCheckout} />
          </div>
          <div className="mt-8">
            {cart.items.map((item) => (
              <div key={item.id} className="flex flex-col md:flex-row border-b border-gray-400 py-4">
                <div className="flex-shrink-0">
                  <img src={item.image} alt={item.slug} className="w-32 h-32 object-cover" />
                </div>
                <div className="mt-4 md:mt-0 md:ml-6">
                  <h2 className="text-lg font-bold">{item.slug}</h2>
                  <p className="mt-2 text-gray-600">${item.price.toFixed(2)}</p>
                  <div className="mt-4 flex items-center">
                    <span className="mr-2 text-gray-600">Quantity:</span>
                    <div className="flex items-center">
                      <button
                        className="bg-gray-200 rounded-l-lg px-2 py-1"
                        onClick={() => handleQuantityChange(item.id, false)}
                        disabled={item.quantity <= 1}
                      >
                        -
                      </button>
                      <span className="mx-2 text-gray-600">{item.quantity}</span>
                      <button
                        className="bg-gray-200 rounded-r-lg px-2 py-1"
                        onClick={() => handleQuantityChange(item.id, true)}
                      >
                        +
                      </button>
                    </div>
                  </div>
                  <button
                    className="bg-white text-black border-2 border-black font-medium py-1 px-5 mt-4"
                    onClick={() => removeFromCart(item.id)}
                  >
                    Remove
                  </button>
                </div>
              </div>
            ))}
          </div>
          <div className="flex justify-end items-center mt-8">
            <span className="text-gray-600 mr-4">Total:</span>
            <span className="text-xl font-bold">
              ${cart.items.reduce((sum, item) => sum + item.price * item.quantity, 0).toFixed(2)}
            </span>
          </div>
        </div>
      )}
    </div>
  );
};

export default CartPage;
