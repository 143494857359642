import React from 'react';
import { useCart } from '../common/CartContext';
import { useStripe, useElements, CardElement } from '@stripe/react-stripe-js';

interface CheckoutFormProps {
  charityName: string; // Accept charity name as a prop
}

const CheckoutForm: React.FC<CheckoutFormProps> = ({ charityName }) => {
  const stripe = useStripe();
  const elements = useElements();
  const { cart } = useCart();

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();

    if (!stripe || !elements) {
      return;
    }

    const cardElement = elements.getElement(CardElement);
    if (!cardElement) {
      return;
    }

    // Call your backend to create a payment intent, including the charity name
    const response = await fetch('/create-checkout-session', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ items: cart, charity_name: charityName }), // Send charity name in request
    });

    const { id } = await response.json();

    // Confirm the payment
    const { error } = await stripe.confirmCardPayment(id, {
      payment_method: {
        card: cardElement,
      },
    });

    if (error) {
      console.error(error);
    } else {
      console.log('Payment successful');
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      {/* Stripe Card Element */}
      <div className="my-4">
        <CardElement />
      </div>

      {/* Checkout Button */}
      <button type="submit" disabled={!stripe} className="bg-black text-white font-bold py-2 px-4 rounded">
        Pay
      </button>
    </form>
  );
};

export default CheckoutForm;
