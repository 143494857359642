import Model3Card, { Model3CardProps } from "../components/Model3Card";
import ModelCard, { ModelCardProps } from "../components/modelCard";

const cardsData: ModelCardProps[] = [
  {
    image:
      "https://neoval-direct.sirv.com/sixsixone/model03/model%203%20%2C%2C%20The%20Numbers''%201-9/171A1407%203.jpg?w=1440&h=1438&q=60&scale.option=noup&cid=d24191022370720",
    slug: "elon-musk",
    modelType: 3,
    number: 1,
    title: "Elon Musk",
    isComingSoon: true,
  },
  {
    image:
      "https://neoval-direct.sirv.com/sixsixone/model03/model%203%20%2C%2C%20The%20Numbers''%201-9/171A1722%201.jpg?w=1440&h=1438&q=60&scale.option=noup&cid=d24191022400720",

    slug: "lanzo-ball",
    modelType: 3,
    number: 2,
    title: "Lanzo Ball",
    isComingSoon: true,
  },
  {
    image:
      "https://neoval-direct.sirv.com/sixsixone/model03/model%203%20%2C%2C%20The%20Numbers''%201-9/171A1366.jpg?w=1440&h=1438&q=60&scale.option=noup&cid=d24191022190720",
    slug: "chris-paul",
    modelType: 3,
    number: 3,
    title: "Chris Paul",
    isComingSoon: true,
  },
  {
    image:
      "https://neoval-direct.sirv.com/sixsixone/model03/model%203%20%2C%2C%20The%20Numbers''%201-9/171A1343-2%201%20copy.jpg?w=1440&h=1438&q=60&scale.option=noup&cid=d24191022110720",
    slug: "day-prescott",
    modelType: 3,
    number: 4,
    title: "Day Prescott",
    isComingSoon: true,
  },
  {
    image:
      "https://neoval-direct.sirv.com/sixsixone/model03/model%203%20%2C%2C%20The%20Numbers''%201-9/171A1299%20copy.jpg?w=1440&h=1438&q=60&scale.option=noup&cid=d24191022360720",

    slug: "garry-vee",
    modelType: 3,
    number: 5,
    title: "Garry Vee",
    isComingSoon: true,
  },

  {
    image:
      "https://neoval-direct.sirv.com/sixsixone/model03/model%203%20%2C%2C%20The%20Numbers''%201-9/171A1381.jpg?w=1440&h=1438&q=60&scale.option=noup&cid=d24191022320720",
    slug: "leBron-james",
    modelType: 3,
    number: 6,
    title: "LeBron James",
    isComingSoon: true,
  },
  {
    image:
      "https://neoval-direct.sirv.com/sixsixone/model03/model%203%20%2C%2C%20The%20Numbers''%201-9/171A1228.jpg?w=1440&h=1438&q=60&scale.option=noup&cid=d24191022080720",
    slug: "ronaldo",
    modelType: 3,
    number: 7,
    title: "Ronaldo",
    isComingSoon: true,
  },
  {
    image:
      "https://neoval-direct.sirv.com/sixsixone/model03/model%203%20%2C%2C%20The%20Numbers''%201-9/171A1354%201.jpg?w=1440&h=1438&q=60&scale.option=noup&cid=d24191022410720",

    slug: "lamar-jackson",
    modelType: 3,
    number: 8,
    title: "Lamar Jackson",
    isComingSoon: true,
  },
  {
    image:
      "https://neoval-direct.sirv.com/sixsixone/model03/model%203%20%2C%2C%20The%20Numbers''%201-9/171A1397.jpg?w=1440&h=1438&q=60&scale.option=noup&cid=d24191022280720",

    slug: "joe-burrow",
    modelType: 3,
    number: 9,
    title: "Joe Burrow",
    isComingSoon: true,
  },
];

export default function Model3Page() {
  return (
    <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-6 md:px-0 px-8 mx-auto h-full">
      {cardsData.map((card, index) => (
        <ModelCard key={index} {...card}
        fullHeightImages={true} />
      ))}
    </div>
  );
}
