// src/components/Processing.tsx

import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { showToast } from '../hooks/toastUtils';

const Processing: React.FC = () => {
    const navigate = useNavigate();

    useEffect(() => {
        const updateInventory = async () => {
            const urlParams = new URLSearchParams(window.location.search);
            const session_id = urlParams.get('session_id');
            const transaction_id = urlParams.get('transaction_id');


            if (!session_id) {
                showToast('Invalid session. Please try again.', 'error');
                navigate('/payment_cancel');
                return;
            }

            try {
                const baseUrl = process.env.REACT_APP_BACKEND_URL;
                const response = await fetch(`${baseUrl}/processing?session_id=${session_id}&transaction_id=${transaction_id}`);
                if (response.ok) {
                    // Redirect to success page
                    navigate('/payment_success');
                } else {
                    // Handle error and redirect to cancel page
                    showToast('Payment processing failed. Please contact support.', 'error');
                    navigate('/payment_cancel');
                }
            } catch (error) {
                showToast('An error occurred while processing payment.', 'error');
                navigate('/payment_cancel');
            }
        };

        updateInventory();
    }, [navigate]);

    return (
        <div>
            <h2>Processing your payment...</h2>
            {/* Optionally, you can add a loading spinner here */}
        </div>
    );
};

export default Processing;
