import React, { useState } from 'react';

interface CharityModalProps {
  isOpen: boolean;
  onClose: () => void;
  onCharitySubmit: (charityName: string) => void; // Function to handle charity name submission
}

const CharityModal: React.FC<CharityModalProps> = ({ isOpen, onClose, onCharitySubmit }) => {
  const [charityName, setCharityName] = useState<string>(''); // State for charity name

  if (!isOpen) return null;

  const handleSubmit = () => {
    if (charityName.length >= 3) {
      onCharitySubmit(charityName); // Pass the entered charity name back
      onClose(); // Close the modal
    } else {
      alert('Charity name must be at least 3 characters long.'); // Handle validation
    }
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-70 flex justify-center items-center">
      <div className="bg-gray-900 p-8 rounded-lg shadow-xl max-w-sm w-full text-center">
        <h2 className="text-2xl font-bold text-white mb-6">Enter Charity Name</h2>
        <p className="text-gray-400 mb-8">Please enter the charity name before proceeding with checkout:</p>

        {/* Charity Name Input */}
        <input
          type="text"
          value={charityName}
          onChange={(e) => setCharityName(e.target.value)}
          placeholder="Enter charity name"
          className="w-full bg-gray-800 text-white py-2 px-4 rounded mb-4"
        />

        {/* Submit Button */}
        <button
          className="mt-6 px-6 py-2 bg-white text-black font-semibold rounded-md hover:bg-gray-300 transition-colors mx-2"
          onClick={handleSubmit}
        >
          Submit Charity
        </button>

        {/* Cancel Button */}
        <button
          className="mt-2 px-6 py-2 bg-gray-700 text-white rounded-md hover:bg-gray-600 transition-colors"
          onClick={onClose}
        >
          Cancel
        </button>
      </div>
    </div>
  );
};

export default CharityModal;
