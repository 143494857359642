import React, { useState } from 'react'
import { loadStripe } from '@stripe/stripe-js'
import { Elements } from '@stripe/react-stripe-js'
import CheckoutForm from './CheckoutForm'
import CharityModal from './CharityModal'

const stripePromise = loadStripe('your-publishable-key-here')

const CheckoutPage = () => {
  const [charityName, setCharityName] = useState<string | null>(null)
  const [showModal, setShowModal] = useState(false)
  const [showCheckoutForm, setShowCheckoutForm] = useState(false)

  const handleCheckoutClick = () => {
    setShowModal(true)
  }

  const handleCharitySubmit = (name: string) => {
    setCharityName(name)
    setShowModal(false)
    setShowCheckoutForm(true)
  }

  return (
    <div>
      {showModal && (
        <CharityModal
          isOpen={showModal}
          onCharitySubmit={handleCharitySubmit}
          onClose={() => setShowModal(false)}
        />
      )}

      {showCheckoutForm && charityName && (
        <Elements stripe={stripePromise}>
          <CheckoutForm charityName={charityName} />{' '}
        </Elements>
      )}

      {!showCheckoutForm && (
        <button
          role='link'
          onClick={handleCheckoutClick}
          className='bg-black text-white font-bold py-2 px-4 rounded'
        >
          Checkout
        </button>
      )}
    </div>
  )
}

export default CheckoutPage
