import React, { useState } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import CharityModal from '../pages/common/CharityModal'; // Assuming CharityModal is for input

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY || '');

interface CheckoutButtonProps {
  items: { id: string; slug: string; price: number; quantity: number }[];
}

const CheckoutButton: React.FC<CheckoutButtonProps> = ({ items }) => {
  const backendUrl = process.env.REACT_APP_BACKEND_URL;
  const [showModal, setShowModal] = useState(false); // To control the modal visibility
  const [charityName, setCharityName] = useState<string | null>(null); // Charity name input by the user

  // Handle checkout button click to show charity input modal
  const handleClick = () => {
    setShowModal(true);
  };

  // Handle charity name input and proceed to checkout
  const handleCharitySubmit = async (name: string) => {
    setCharityName(name);
    setShowModal(false);

    console.log(`Proceeding with charity: ${name}`);

    const stripe = await stripePromise;

    if (!stripe) {
      console.error('Stripe.js failed to load.');
      return;
    }

    try {
      // Proceed to call backend with items and charity name
      const response = await fetch(`${backendUrl}/create-checkout-session`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ items, charity_name: name }), // Sending charity_name in the body
      });

      if (!response.ok) {
        throw new Error('Network response was not ok.');
      }

      const sessionId = await response.json();

      // Redirect to Stripe Checkout
      const result = await stripe.redirectToCheckout({
        sessionId: sessionId.id,
      });

      if (result.error) {
        console.error(result.error.message);
      }
    } catch (error) {
      console.error('Error during checkout:', error);
    }
  };

  return (
    <div>
      {/* Render CharityModal if showModal is true */}
      {showModal && (
        <CharityModal
          isOpen={showModal}
          onCharitySubmit={handleCharitySubmit} // Handle charity submission
          onClose={() => setShowModal(false)} // Close the modal
        />
      )}

      {/* Checkout button */}
      <button
        role="link"
        onClick={handleClick}
        className="bg-black text-white font-bold py-2 px-4 rounded"
      >
        Checkout
      </button>
    </div>
  );
};

export default CheckoutButton;
