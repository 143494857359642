import { useNavigate } from 'react-router';
import cn from 'clsx';

export type ModelCardProps = {
  image: string;
  number: number;
  modelType: 1 | 2 | 3;
  title?: string;
  slug?: string;
  fullHeightImages?: boolean;
  useContain?: boolean;
  isComingSoon?: boolean; // Add this prop to identify cards that are "Coming Soon"
};

export default function ModelCard(props: ModelCardProps) {
  const { image, title, slug, modelType, fullHeightImages, useContain, isComingSoon } = props;
  const navigate = useNavigate();

  const handleNavigate = () => {
    if (!isComingSoon && slug) {
      navigate(`/model/${modelType}/${slug}`);
    }
  };

  return (
    <div className="container mx-auto relative">
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-1 gap-6">
        <div className="pb-4">
          <div
            onClick={handleNavigate}
            className={cn('flex flex-col items-center w-full cursor-pointer relative', {
              'pointer-events-none': isComingSoon, // Disable click if "Coming Soon"
            })}
          >
            {/* Apply blur when the card is "Coming Soon" */}
            <img
              src={image}
              className={cn('w-full transition-all', {
                'h-[400px]': !fullHeightImages,
                'h-full': fullHeightImages,
                'object-contain': useContain,
                'object-cover': !useContain,
                'blur-sm': isComingSoon, // Apply blur if "Coming Soon"
              })}
              alt=""
            />
            {title && (
              <span className="mt-2 text-center font-light text-sm text-gray-700">
                {title}
              </span>
            )}

            {/* Show "Coming Soon" text if applicable */}
            {isComingSoon && (
              <div className="absolute inset-0 flex items-center justify-center bg-black bg-opacity-1">
                <span className="text-white text-lg font-semibold">Coming Soon</span>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
